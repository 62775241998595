.mintContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.mintButton {
  font-size: 0.9rem;
}

.connectButton {
  font-size: 0.9rem;
}

.renderMintedUnits {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.renderDefaultImage {
  justify-content: center;
  height: 100%;
  display: flex;
}

.renderDefaultImage img {
  scale: 80%
}

.backgroundContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.backgroundImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.contentContainer {
  position: relative;
  margin-top: 20px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 50% 50%;
  width: 60vw;
  min-height: 80vh;
  margin: auto;
  background-color: rgba(33, 33, 33, 0.8);
}

.textContainer {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mintingContainer {
  height: 80vh;
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mintButton {
  margin-bottom: 1rem;
}

.mintButtonArea {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 1px;
  padding: 1px;
}

.alertContainer {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(33, 33, 33, 0.8);
}

.connectWrapper {
  margin-bottom: 10px;
}

@media screen and (orientation: portrait) {
  .contentContainer {
    flex-direction: column;
    height: 100vh;
  }

  .textContainer {
    order: 2;
    height: 50%;
    overflow-y: scroll;
  }

  .mintingContainer {
    order: 1;
    height: 50%;
  }
}