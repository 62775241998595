.sidebar-container {
  background-color: rgba(33,33,33,0.7);
  border: 1px solid #ccc;
  margin: 1.5em;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: inline-block;
  transition: height 0.5s ease-in-out;
  padding: 0.5em;
}


ul.menu-items {
  background-color: transparent;
  list-style-type: none;
  padding-left: 2em;
  margin-left: 0em;
}

ul.menu-items > li {
  position: relative;
}

.selected-hash {
  font-size: 1.2em;
  content: "//";
  position: absolute;
  left: -1em;
  top: 0;
}


.sidebar-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.menu-items button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  padding: 3px
}

li.launchgame a {
font-family: inherit;
background-color: transparent;
border: none;
color: white;
cursor: pointer;
padding: 3px;
text-decoration: none;
}


#mainMenuTitle {
  font-size: 1.2em;
  font-weight: 700;
  color: white;
  margin-left: 1em;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}
.social-icons {
  display: flex;
  justify-content: space-evenly;
}

.social-icons a img {
  width: 10px;
  height: 10px;
}
.fade-in {
  opacity: 1;
  transition: opacity 3s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.3s ease;
}

@media (pointer: coarse) {
  .sidebar-container {
  background-color: rgba(33,33,33,0.7);
  border: 1px solid #ccc;
  margin: 0.5em;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: inline-block;
  transition: height 0.5s ease-in-out;
}
  .menu-items button {
    font-size: 0.8em;
  }
  li.launchgame a {
    font-size: 0.8em;
  }
  #mainMenuTitle {
    margin-left: 0.5em;
    font-size: 1em;
  }
  .social-icons a img {
  width: 15px;
  height: 15px;
  }
  
}
@media (pointer: fine), (pointer: none) {
  .menu-items button {
    font-size: 1em;
  }
  li.launchgame a {
    font-size: 1em;
  }
  #mainMenuTitle {
    margin-left: 0.5em;
    font-size: 1.2em;
  }
  .social-icons a img {
  width: 20px;
  height: 20px;
  }
}

@media (pointer: fine), (any-pointer: coarse) {
  .menu-items button {
    font-size: 1em;
  }
  li.launchgame a {
    font-size: 1em;
  }
  #mainMenuTitle {
    margin-left: 0.5em;
    font-size: 1.2em;
  }
  .social-icons a img {
  width: 20px;
  height: 20px;
  }
}
