
/* General Styling */
body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  color: #FFF;
  font-size: 1em;
}

@font-face {
  font-family: "BlenderProBook";
  src: url("./Blender-Pro-Book.otf") format("opentype");
}

* {
  font-family: "BlenderProBook", sans-serif;
}

.App {
  margin: 0;
  padding: 0;
  top: 0px;
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


.section-block {
  position: relative;
  z-index: auto; /* This allows z-index to be set dynamically */
  min-height: 100vh;
}