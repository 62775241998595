   .title{
    font-size: 4vw;
    font-weight: 500;
    margin-bottom: 0vh;
  }
  
   .title_font{
      font-size: 4rem;
      font-family: "Bebas Neue", sans-serif;
   }
   
   .content_text{
    text-align: left !important;
  }
  
   .subtitle{
    font-size: 1.2vw;
    font-weight: 500;
   }
  
   .white_border{
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border: 2px solid #ffffff;
      margin: 5px;
    }
  
    .selection_visual{
      height: 400px;
    }
  
    .link{
      color: white !important;
      /* text-decoration: underline !important; */
      /* text-align: left !important; */
    }
  
   .choice{
    font-size: 2.5vw;
    /* font-weight: bold; */
   }
  
   .home_container{
    width: 65vw !important;
   }
  
   .home_title{
    font-size: 2vw;
   }
  
   #black{
    background-color: black !important;
   }
  
  .figure_image{
    margin-bottom: 0;
  }
  
   .background{
    background-color: black;
    /* min-height: 70vh; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    max-width: 100vw;
    min-height: 100vh;
    /* height:; */
   }
  
  .visual{
    /* height: 30vw; s */
    max-height: 500px;
    max-width: 500px;
    /* max-width: 20vw; */
  }
  
  .text-left{
    text-align: left;
  }
  
  
  #App_row{
    margin: 0;
    max-width: 100vw;
    width: 100%;
  }
  
  #description_row{
    margin-bottom: 50px;
  }
  
  #visual_row{
    margin-bottom: 20px;
  }
  
  #audio_row{
    margin-bottom: 20px;
  }
  
  /*#button_row{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5vh 0vh 5vh 0vh;
  }*/
  
  #mint_button{
    color: black;
    background-color: white !important;
    border-color: black;
    width: 12em;
    border-radius: 0 !important;
  }
  
  #build_button{
    color: white;
    background-color: black !important;
    border-color: white;
    width: 12em;
    border-radius: 0 !important;
  }
  
  #UI_row{
    display: flex;
    justify-content: center;
  }
  
  .interface_button{
    /* background-color: black !important; */
    width: 15em;
    /* border: 2px solid #FFFFFF !important; */
    border-radius: 0 !important;
  }
  
  #wl_badge{
    width: 16em !important;
    border-radius: 0 !important;
    font-weight: 400 !important;
  }
  
  #claim_badge{
    width: 16em !important;
    border-radius: 0 !important;
    font-weight: 400 !important
  }
  
  #connexion_status{
    margin-top: 5vh;
    border-radius: 0 !important;
  }
  
  #web3_alert{
    border-radius: 0 !important;
    width: 12em;
    color: white;
    background-color: black !important;
    border: 2px solid #FFFFFF !important;
  }
  
  .etherscan_link{
    color: rgb(119, 119, 119);
  }
  
  #connexion_info{
    color: rgb(119, 119, 119);
    font-size: 0.7em;
    font-weight: lighter;
  }
  
  .no_padding{
    padding: 0 !important;
  }
  
  .interface_col{
    align-content: space-around !important;
  }
  
  .left-items{
    display: flex;
    justify-content: flex-start;
  }
  
  .grow { transition: all .2s ease-in-out; }
  .grow:hover{ transform: scale(1.5); }
  
  .large-text{
    font-size: 1.3rem !important;
  }
  
  /*ul{list-style: none;}
  li::before{
    content: '\25C6   ';
    color: white;
  }*/
  
  .card_body{
    max-width: 400px !important;
    /* justify-content: stretch;
    align-items: stretch;
    align-content: stretch; */
  }
  
  .larger-text{
    font-size: 1.2rem !important;
  }
  
  .card_image{
   /* */
  }
  @media screen and (max-width: 650px) {
  
  
    .home_container{
      width: 100%;
      font-size: 4vw;
    }
  
    .carousel{
      max-height: 75vh;
      max-width: 75vw;
      margin: auto;
    }
  
    .text_left{
      text-align: left !important;
    }
  
    .about_graphic{
      max-width: 80vw;
      /* margin-left: -10vw; */
    }
  
    .home_title{
      font-size: 1.5rem;
      /* white-space: normal; */
    }
  
    .visual{
      /* width: 10% !important;  */
      /* max-width: 100% !important; */
      /* height: 50vw; */
    }
  } 
  
  @media only screen and (max-width: 1200px) {
    .visual{
      height: 30vw;
      /* max-width: 100% !important; */
      /* height:45vw; */
    }
  }
  
  /* media md */
  @media only screen and (max-width: 992px) {
    .xs-center{
      justify-content: center !important;
      text-align: center !important;
    }
  
    /* .xs-button-center{
      justify-content: center !important;
    } */
    .visual{
      height: 50vw;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .selection_visual{
      height: 300px;
    }
    .title_font{
      font-size: 3rem;
   }
  }
  
  @media only screen and (max-width: 600px) {
    .visual{
      height: 50vw;
    }
  
  }