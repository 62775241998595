body #section2{
  background-image: url('/images/hex_background.jpg');
  background-size: cover;
  background-color: #222;
}

.section2Frame {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.section2Content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.videoTitle{
  width: 60%;
}
.videoTitle h1{
  margin-bottom: 2rem;
}
.section2Frame .videoFrame {
  width: 100%;
  height: 100%;
  border: 1px solid white;
  background-color: rgba(10,10,10,.8);
}

.background-video {
  width: 100%;
}
@media screen and (orientation: portrait) {
  .videoTitle{
    width: 90%;
  }
}