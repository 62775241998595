@use "sass:math";
$baseline: 1rem;

$minScreen: 20rem;
$maxScreen: 50rem;
$minFont: .5rem;
$maxFont: 1.2rem;

$h1: 1.55rem;
$h2: 1.2rem;
$h3: 1rem;
$h4: 0.9rem;
$h5: .875rem;


@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
	& {
		@each $property in $properties {
			#{$property}: $min-value;
		}

		@media screen and (min-width: $min-vw) {
			@each $property in $properties {
				#{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}
		}

		@media screen and (min-width: $max-vw) {
			@each $property in $properties {
				#{$property}: $max-value;
			}
		}
	}
}

@function strip-unit($value) {
    @return math.div($value, $value * 0 + 1);
  }

*{
	box-sizing: border-box;
}

html {
	box-sizing: border-box;
	height: 100%;
	font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	@include fluid-type(font-size, $minScreen, $maxScreen, $minFont, $maxFont);
}

body {
	line-height: $baseline;
	padding: 0;
	margin: 0 auto;  
	width: 100%;
}

p{	
	line-height: calc($baseline * 1.01);
	margin-bottom: $baseline;
}

li{
	line-height: calc($baseline * 1.01);
	margin-bottom: calc($baseline * 0.5);
}

h1,
h2,
h3,
h4,
h5{
	font-weight: 700;
	margin-bottom: $baseline;
}

h1 {
	font-size: $h1;
	line-height: $h1;
	margin-top: calc((#{$baseline} - #{$h1}) + #{$baseline});
	margin-bottom: calc(((#{$baseline} - #{$h1}) + #{$baseline})/2);
}

h2 {
	font-size: $h2;
	line-height: $h2;
	margin-top: calc((#{$baseline} - #{$h2}) + #{$baseline});
	margin-bottom: calc(((#{$baseline} - #{$h2}) + #{$baseline})/2);
}

h3 {
	font-size: $h3;
	line-height: $h3;
	margin-top: calc((#{$baseline} - #{$h3}) + #{$baseline});
	margin-bottom: calc(((#{$baseline} - #{$h3}) + #{$baseline})/2);
}

h4 {
	font-size: $h4;
	line-height: $h4;
	margin-top: calc((#{$baseline} - #{$h4}) + #{$baseline});
	margin-bottom: calc(((#{$baseline} - #{$h4}) + #{$baseline})/2);
}

h5 {
	font-size: $h5;
	line-height: $h5;
	margin-top: calc((#{$baseline} - #{$h5}) + #{$baseline});
	margin-bottom: calc(((#{$baseline} - #{$h5}) + #{$baseline})/2);
}