.footer-frame {
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 1em;
  background-color: #333;
  color: #fff;
}

.footer-container {
  width: 60%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}


.footer-column {
  flex: 1;
}

.footer-column h4 {
  margin-top: 0;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li a {
  color: #fff;
  text-decoration: none;
}

.Sub-frame {
  margin: 0;
  padding: 0;
  top: 0px;
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}