.section4-container {
  width: 100%;
}

.section4Frame {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.background-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section4-coverimg {
  /* Add your cover image styling here */
}

.section4-contentFrame {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.section4-content {
  background-color: rgba(33,33,33,0.7);
  position: relative;
  padding: 30px;
  width: 60%;
  z-index: 0;
}

@media screen and (orientation: portrait) {
  .background-container img {
  min-height: 100vh;
  object-fit: cover;
  }
}