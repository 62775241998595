.section5-frame {  
  height: 100vh;
  display: flex;
  flex-direction: column; /* Align items vertically */
  justify-content: center;
  align-items: center;
  position: relative;
}

#section5 {
  position: relative;
  width: 100vw;
  background-image: url('/images/scifi_hex.jpg');  
  background-size: cover;
  z-index: 0;
}

.videoFrame {
  position: relative;
  width: 60%; /* Adjust as needed */
  height: calc(60vw * 359 / 635); /* Maintain aspect ratio */
}

.video-player {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%; /* Adjust as needed */
  height: auto;
}

.video-menu {
}

.video-menu ul {
  list-style: none;
}

.video-menu li {
  padding: 0.1rem;
  cursor: pointer;
}

.video-title {
  font-size: 2em;
  position: absolute;
  top: -5px;
  left: -5px;
  color: white;
  background-color: rgba(88, 88, 88, 0.7);
  padding: 5px 10px;
  z-index: 1;
}

@media screen and (orientation: portrait) {
  .videoFrame {
  position: relative;
  width: 90%; /* Adjust as needed */
  height: calc(60vw * 359 / 635); /* Maintain aspect ratio */
  }
}


@media (max-width: 768px) {
  .video-title {
    top: -2.5em;
    left: -2px;
    font-size: 1em;
  }
}