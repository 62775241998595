/* General Styling */

#section1 .sectionFrame {
  height: 100vh;
  background-size: cover;
  background-position: left;
  background-image: url('/images/legion.jpg');
  background-color: #000;
}

/* Landscape styles */
@media screen and (orientation: landscape) {
  #section1 .sectionFrame {
    background-position: left;
  }
}

/* Portrait styles */
@media screen and (orientation: portrait) {
  #section1 .sectionFrame {
    background-position: 33%;
  }
}

.logoSpacer {
  display: flex;
  justify-content: center;  /* Center horizontally */
  align-items: center;      /* Center vertically */
  height: 100vh;            /* Full height */
}

.logoBox {
  padding: 20px;
  max-width: 30vw;
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center;     /* Vertically center */
  flex-direction: column;  /* Stack items vertically */
  background-color: rgba(0,0,0,.7); 
}

.logo img {
  max-width: 100%;
  height: auto;
  padding-bottom: 1vh;
  align-items: center;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center horizontally */
}

.custom-button {
  background-color: transparent;
  background-size: cover;
  position: relative;
  width: 20vw;
  max-width: 250px;
  padding-top: calc(20vw * 75 / 305);
  border: none;
  cursor: pointer;
}

.button-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.button-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* Text color */
}

.taglineFrame{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tagline {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tagline h2 {
  margin: 0px;
}