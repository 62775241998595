.section3Frame {
  position: relative;
  height: 100%;
  background-size: cover;
  background-position: left;
  background-image: url('/images/litepaperBG.jpg');
  background-color: #000;
}
.section3BG {
  position: absolute;
  width: 100vw;
  height: 100%;
  background-color: #333;
  z-index: -1;
}

.background-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.background-container img {
  width: 100vw;
}

.section3-coverimg {
  position: relative;
  height: 0vh;
}

.section3-contentFrame {
  display: flex;
  justify-content: center;
  position: relative;
}

.section3-content {
  background-color: rgba(33,33,33,0.7);
  position: relative;
  padding: 30px;
  width: 60%;
  z-index: 0;
  margin-bottom: 100vh;
}

#s3contentspacer {
  height: 10vh;
}

#litePaper {
  font-size: 3em;
  padding-bottom: 1em;
}

@media screen and (orientation: portrait) {
  .section3-content {
    background-color: rgba(33,33,33,0.7);
    position: relative;
    padding: 10px;
    width: 80%;
    z-index: 0;
    margin-bottom: 20vh;
  }
}